import React from "react";
import { NextPageWithLayoutProps } from "@/src/types";

type Props<P> = {
  Page: NextPageWithLayoutProps<P>;
  Layout: React.FC<{ children: React.ReactNode; pageProps: P }>;
};

function NextPageWithLayout<T>({ Page, Layout }: Props<T>) {
  Page.getLayout = function getLayout(page: React.ReactElement, pageProps: T) {
    return <Layout pageProps={pageProps}>{page}</Layout>;
  };
  return Page;
}

export default NextPageWithLayout;
