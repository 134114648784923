import { LAPTOP_BREAKPOINT } from "@/constants/app";
import { isClient } from "@/libs/utils";
import {
  useEventListener,
  useIsomorphicLayoutEffect,
  useResizeObserver,
} from "@react-hookz/web";
import { PropsWithChildren, useRef, useState } from "react";

/* 

NOTE: This component will take the natural height of an element and round it up to fit the grid system. 

Example responsive:
 
<GridBackground>
  <Grid>
    <GridContent classes={{ root: "col-span-5 col-start-2 p-6" }}>
      <Headline size="sm">
        This content will reflow and resize the container but the container height will be rounded up to fit the grid.
      </Headline>
    </GridContent>
  </Grid>
</GridBackground>

Example fixed rows:

<GridBackground>
  <Grid>
    <GridContent classes={{ root: "col-span-5 col-start-2" }} rows={1}>
      ...
    </GridContent>
  </Grid>
</GridBackground>

 **/

type GridContentProps = PropsWithChildren<{
  classes?: { inner?: string; root?: string };
  /* Optional number of rows to fix the height to */
  rows?: GridContentRows;
}>;
export type GridContentRows = number | GridContentRowsMediaQuery;
export type GridContentRowsMediaQuery = { mobile: number; laptop: number };

const MOBILE_GRID_COLUMNS = 8;
const DESKTOP_GRID_COLUMNS = 12;
const BASE_REM_PX = 16;

export function GridContent(props: GridContentProps) {
  const [height, setHeight] = useState<number | string | undefined>(undefined);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(isClient());
  const ref = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const hasSetRows = !!props.rows;

  function setContainerHeight(el: HTMLDivElement) {
    const mobilePad =
      getComputedStyle(document.documentElement).getPropertyValue(
        "--site-padding-mobile",
      ) || "0";
    const laptopPad =
      getComputedStyle(document.documentElement).getPropertyValue(
        "--site-padding-laptop",
      ) || "0";
    const fullMobilePad = parseFloat(mobilePad) * BASE_REM_PX * 2;
    const fullLaptopPad = parseFloat(laptopPad) * BASE_REM_PX * 2;

    const isMobile = window.innerWidth < LAPTOP_BREAKPOINT;
    const gridWidth =
      window.innerWidth - (isMobile ? fullMobilePad : fullLaptopPad);
    const colWidth =
      gridWidth / (isMobile ? MOBILE_GRID_COLUMNS : DESKTOP_GRID_COLUMNS);

    // NOTE: If rows are set, use that instead of the natural height
    if (hasSetRows) {
      if (typeof props.rows === "object") {
        const { mobile: rowsMobile = 0, laptop: rowsLaptop = 0 } = props.rows;
        const mediaRows = isMobile ? rowsMobile : rowsLaptop;
        const containerHeight = mediaRows * colWidth;
        return setHeight(containerHeight);
      }
      if (typeof props.rows === "number") {
        const rows = props.rows;
        const containerHeight = rows * colWidth;
        return setHeight(containerHeight);
      }
    }

    const rect = el.getBoundingClientRect();
    const height = rect.height;
    const containerHeight = Math.ceil(height / colWidth) * colWidth;

    return setHeight(containerHeight);
  }

  function handleShouldUpdate() {
    if (ref.current) {
      setContainerHeight(ref.current);
      setShouldUpdate(false);
    }
  }

  function handleEvents() {
    if (!shouldUpdate) {
      setShouldUpdate(true);
    }
  }

  // Events
  useEventListener(isClient() ? window : null, "resize", () => {
    handleEvents();
  });

  useResizeObserver(innerRef, () => {
    handleEvents();
  });

  // Updates
  useIsomorphicLayoutEffect(() => {
    handleShouldUpdate();
  }, [shouldUpdate]);

  return (
    <div
      ref={ref}
      className={props?.classes?.root}
      // NOTE: be aware this will override any height set by className
      // This will reset the height before performing the calculation
      style={{ height: shouldUpdate ? undefined : height }}
    >
      {/* NOTE: This must be used to capture the natural height of content through a ResizeObserver even when the height of the parent is fixed. */}
      <div ref={innerRef} className={props?.classes?.inner}>
        {props.children}
      </div>
    </div>
  );
}
