import clsx from "clsx";
import { PropsWithChildren } from "react";
import { ContentWrapper } from "@/src/Onboarding/ContentWrapper";
import styles from "./GridBackground.module.css";

type BackgroundGridProps = PropsWithChildren<{
  className?: string;
  innerClassName?: string;
}>;

export function GridBackground(props: PropsWithChildren<BackgroundGridProps>) {
  return (
    <ContentWrapper className={clsx(props.className, styles.gridY)}>
      <div className={clsx(props.innerClassName, styles.gridX)}>
        {props.children}
      </div>
    </ContentWrapper>
  );
}
