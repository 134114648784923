import clsx from "clsx";
import { PropsWithChildren } from "react";

type ContentWrapperProps = PropsWithChildren<{
  className?: string;
}>;

export function ContentWrapper(props: ContentWrapperProps) {
  return (
    <div
      className={clsx(
        props.className,
        "px-sitePaddingMobile laptop:px-sitePaddingLaptop",
      )}
    >
      {props.children}
    </div>
  );
}
