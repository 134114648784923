import * as React from "react";
import NikeSvg from "@/src/Onboarding/NikeSvg";
import { colors } from "@/libs/themes";
import { Copy } from "../../../Onboarding/common/Copy";
import { Headline } from "../../../Onboarding/common/Headline";
import SiteFooter from "../../../components/SiteFooter/SiteFooter";
import { GridBackground } from "../../../Onboarding/GridBackground";

const CountryMessage = () => {
  return (
    <GridBackground
      className="theme-onboarding flex-1 flex-col"
      innerClassName="h-full"
    >
      <div className="flex h-full flex-col p-1">
        <div className=" laptop:ml-20 laptop:self-baseline flex basis-4/5 flex-col justify-center self-center">
          <NikeSvg color={colors.pearlWhite} classes="w-20 my-4" />
          <Headline size="md" className="my-6">
            IT&#39;S NOT YOU.
            <br />
            IT&#39;S US
          </Headline>
          <Copy size="base" className="laptop:max-w-xl my-6 max-w-md">
            .SWOOSH is not available in your location. Nike is required to
            comply with local regulations that can differ on a country by
            country basis. For this reason, .SWOOSH experiences are currently
            not supported in some countries.
          </Copy>
        </div>
        <div className="flex h-auto flex-1 justify-end">
          <SiteFooter className="laptop:mb-10 flex-col self-end" />
        </div>
      </div>
    </GridBackground>
  );
};

export default CountryMessage;
