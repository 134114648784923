import CountryMessage from "./components/CountryMessage";

const Location = () => {
  return (
    <div className="theme-onboarding flex h-screen flex-col">
      <CountryMessage />
    </div>
  );
};

export default Location;
