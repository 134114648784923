import { NoProviderPageProps } from "@/src/types/next";
import { ErrorBoundary } from "react-error-boundary";
import AppErrorBoundary from "../AppErrorBoundary";

type Props = {
  children: React.ReactNode;
  pageProps: NoProviderPageProps;
};

const NextPageNoProviderLayout: React.FC<Props> = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={AppErrorBoundary}>
      {children}
    </ErrorBoundary>
  );
};

export default NextPageNoProviderLayout;
