import { Country } from "@/libs/geo/countries";
import clsx from "clsx";
import { AnchorHTMLAttributes, PropsWithChildren, ReactNode } from "react";
import {
  getLocalizedLegalAgreement,
  AgreementType,
} from "../Link/LegalAgreementLink";
import { ContentWrapper } from "@/src/Onboarding/ContentWrapper";
import { GridBackground } from "../../Onboarding/GridBackground";
import { GridContent } from "../../Onboarding/GridContent";
import {
  EXTERNAL_LINK_DOT_SWOOSH_MEDIUM_BLOG,
  EXTERNAL_LINK_DOT_SWOOSH_INSTAGRAM,
  EXTERNAL_LINK_DOT_SWOOSH_TWITTER,
  EXTERNAL_LINK_CONTACT_US,
} from "@/constants/app";
import { useGeoLoc } from "@/libs/geo/GeoLocProvider";
import { isCountryASupportedEMEACountry } from "../GlobalElements/CookieWall/helpers";

type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;
type Props = {
  className?: string;
  setOpenCookieBanner?: (boolean: boolean) => void;
};
interface WrapperProps {
  gridView: boolean;
  children: ReactNode;
}

function FooterLink({ children, ...props }: PropsWithChildren<LinkProps>) {
  return (
    <div>
      <a
        className="hover:text-emphasis cursor-pointer"
        target="_blank"
        {...props}
      >
        {children}
      </a>
    </div>
  );
}

const Wrapper = ({ gridView, children }: WrapperProps) => {
  return gridView ? (
    <GridBackground>
      <GridContent
        classes={{ root: "flex items-end py-10 desktop:pt-0", inner: "w-full" }}
      >
        {children}
      </GridContent>
    </GridBackground>
  ) : (
    <ContentWrapper>{children}</ContentWrapper>
  );
};

export const SiteFooterComponent = ({
  className,
  setOpenCookieBanner,
}: Props) => {
  const geoLoc = useGeoLoc();
  const country = geoLoc.country as Country;

  const TERMS_LINK = getLocalizedLegalAgreement(
    AgreementType.TermsOfService,
    country,
  );
  const PRIVACY_LINK = getLocalizedLegalAgreement(
    AgreementType.PrivacyPolicy,
    country,
  );

  return (
    <footer
      className={clsx(
        className,
        "theme-onboarding z-footer desktop:bg-transparent relative w-full bg-transparent py-6",
        "desktop:mt-[-4rem]",
      )}
    >
      <Wrapper gridView={false}>
        <div className="laptop:flex-row laptop:justify-between flex flex-col-reverse gap-14 text-sm">
          <div className="laptop:grid-cols-2 desktop:grid-flow-col desktop:grid-cols-none grid flex-col flex-wrap gap-6">
            <p>
              &copy;{new Date().getFullYear()} Nike, Inc. All Rights Reserved
            </p>
            <FooterLink href={TERMS_LINK}>.SWOOSH Terms of Service</FooterLink>
            <FooterLink href={PRIVACY_LINK}>.SWOOSH Privacy Policy</FooterLink>
            {setOpenCookieBanner &&
              isCountryASupportedEMEACountry(geoLoc.country as Country) && (
                <FooterLink onClick={() => setOpenCookieBanner(true)}>
                  Cookie Settings
                </FooterLink>
              )}

            <FooterLink href={EXTERNAL_LINK_CONTACT_US}>Get Help</FooterLink>
          </div>
          <div className="laptop:flex-row flex flex-col gap-7">
            {/* Replace w/ blog for now: https://jira.nike.com/browse/NVS-760 */}
            <FooterLink href={EXTERNAL_LINK_DOT_SWOOSH_MEDIUM_BLOG}>
              Blog
            </FooterLink>
            <FooterLink href={EXTERNAL_LINK_DOT_SWOOSH_INSTAGRAM}>
              Instagram
            </FooterLink>
            <FooterLink href={EXTERNAL_LINK_DOT_SWOOSH_TWITTER}>
              Twitter
            </FooterLink>
          </div>
        </div>
      </Wrapper>
    </footer>
  );
};

export default SiteFooterComponent;
