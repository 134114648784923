import clsx from "clsx";
import { HTMLAttributes, PropsWithChildren } from "react";

// NOTE: Talk with design team to keep naming in sync
export type CopySizes = "sm" | "base" | "md" | "lg" | "xl" | "2xl";

type CopyProps = {
  as?: "p" | "div" | "span";
  size: CopySizes;
} & HTMLAttributes<HTMLParagraphElement>;

export const COPY_SIZE_MAP = {
  sm: "text-xs",
  base: "text-base",
  md: "text-lg",
  lg: "text-xl",
  xl: "text-3xl",
  "2xl": "text-6xl",
};

export function Copy({
  as: Component = "p",
  size,
  children,
  className,
  ...props
}: PropsWithChildren<CopyProps>) {
  return (
    <Component
      className={clsx(
        "leading-none", // NOTE: these should only be styles shared by all copy.
        COPY_SIZE_MAP[size],
        className,
      )}
      {...props}
    >
      {children}
    </Component>
  );
}
